
import {
  defineComponent
} from "@/components/CyanPageWrapper.vue";
import GenericPicker from "@/components/GenericPicker.vue";

export default defineComponent({
  name: "DirPicker",
  components: {
    GenericPicker,
  },
});
