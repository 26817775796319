import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_generic_picker = _resolveComponent("generic-picker")

  return (_openBlock(), _createBlock(_component_generic_picker, {
    nav1: "DIRECTORIO",
    myUrl: "dirPicker",
    centroUrl: "dirPickCentro"
  }))
}